<mat-tab-group>
    <mat-tab label="{{ 'tabs.transfers' | translate }}"> 
        <form class="registerServiceForm" id="formBooking" [formGroup]="registerServiceForm" *ngIf="!bookingConfirmation">
            <div class="form-row">
                <div class="form-group col">
                    <div class="dataCustomer">
                        <div class="d-none">
                            <label for="theme">Theme</label>
                            <select name="theme" id="theme" class="changes">
                                <option value="default" selected>Default</option>
                                <option value="android">Android</option>
                                <option value="android-ics light">Android ICS Light</option>
                                <option value="android-ics">Android ICS</option>
                                <option value="ios">iOS</option>
                                <option value="jqm">Jquery Mobile</option>
                                <option value="sense-ui">Sense UI</option>
                                <option value="wp light">Windows Phone Light</option>
                                <option value="wp">Windows Phone</option>
                            </select>
                        </div>
                        <div class="d-none">
                            <label for="mode">Mode</label>
                            <select name="mode" id="mode" class="changes">
                                <option value="scroller" selected>Scroller</option>
                                <option value="clickpick">Clickpick</option>
                                <option value="mixed">Mixed</option>
                            </select>
                        </div>
                        <div class="d-none">
                            <label for="display">Display</label>
                            <select name="display" id="display" class="changes">
                                <option value="modal" selected>Modal</option>
                                <option value="inline">Inline</option>
                                <option value="bubble">Bubble</option>
                                <option value="top">Top</option>
                                <option value="bottom">Bottom</option>
                            </select>
                        </div>
                        <div class="d-none">
                            <label for="language">Language</label>
                            <select name="language" id="language" class="changes">
                                <option value="">English</option>
                                <option value="hu">Magyar</option>
                                <option value="de">Deutsch</option>
                                <option value="es"selected>Espa�ol</option>
                                <option value="fr">Fran�ais</option>
                                <option value="it">Italiano</option>
                                <option value="no">Norsk</option>
                                <option value="pt-BR">Pr Brasileiro</option>
                                <option value="zh">Chinese</option>
                                <option value="nl">Nederlands</option>
                                <option value="tr">T�rk�e</option>
                                <option value="ja">Japanese</option>
                                <!--Lang-->
                            </select>
                        </div>
                        <div class="d-none">
                            <label for="demo">Demo</label>
                            <select name="demo" id="demo" class="changes">
                                <option value="date" selected>Date</option>
                                <option value="datetime" selected>Datetime</option>
                                <option value="time" >Time</option>
                                <option value="tree_list" >Tree List</option>
                                <option value="image_text" >Image & Text</option>
                                <option value="select" >Select</option>
                                <!--Demos-->
                            </select>
                        </div>
                        <div class="form-group demos" id="demo_default">
                          <label for="Name">{{ 'tabs.pickup_time' | translate }} </label>
                          <input type="text" data-field="datetime" name="test_default" formControlName="test_default" id="test_default" class="date form-control" readonly>
                          <div id="dtBox"></div>
                          <div *ngIf="submitted && testDefaultErrorsRequired" style="width: 100%; margin-top:0.25rem;font-size:80%;color: #dc3545;">
                            <div *ngIf="testDefaultErrorsRequired">{{ 'tabs.errors.pickup_time' | translate }}</div>
                          </div>
                        </div>
                      </div>
                </div>
                <div class="form-group col">
                    <label for="passengers" class="passengers">{{ 'tabs.passengers' | translate }} </label>
                    <select name="passengers" formControlName="passengers" id="passengers" class="form-control">
                      <option *ngFor="let item of items" value="{{item}}">{{item}}</option>
                    </select>
                </div>
                <div class="form-group col">
                    <label>{{ 'tabs.pickup' | translate }}</label>
                    <select name="p1" (change)="openOriginAddress($event)" formControlName="originPickUp" 
                        class="form-control" 
                        [ngClass]="{ 'is-invalid': submitted && f.originPickUp.errors }" >
                        <option value="">{{ 'tabs.options' | translate }}</option>
                        <option value="1">{{ 'tabs.airport' | translate }}</option>
                        <option value="2">{{ 'tabs.train_station' | translate }}</option>
                        <option value="3">{{ 'tabs.others' | translate }}</option>
                    </select>
                    <div *ngIf="submitted && f.originPickUp.errors" class="invalid-feedback">
                        <div *ngIf="f.originPickUp.errors.required">{{ 'tabs.errors.pickup' | translate }}</div>
                    </div>
                </div>
                <div class="form-group col" [ngClass]="{ 'inactive': !showFlightNumber, 'active': showFlightNumber }">
                    <label>{{ 'tabs.flight_number' | translate }}</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        formControlName="flightNumber" 
                        class="flightNumber" 
                        [ngClass]="{ 'is-invalid': submitted && f.flightNumber.errors }">
                    <div *ngIf="submitted && f.flightNumber.errors" class="invalid-feedback">
                        <div *ngIf="f.flightNumber.errors.required">{{ 'tabs.errors.flight_number' | translate }}</div>
                    </div>
                </div>
                <div class="form-group col" [ngClass]="{ 'inactive': !showOriginAddress, 'active': showOriginAddress }">
                    <label>{{ 'tabs.pickup_address' | translate }}</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        formControlName="originAddress" 
                        class="originAddress" 
                        [ngClass]="{ 'is-invalid': submitted && f.originAddress.errors }">
                    <div *ngIf="submitted && f.originAddress.errors" class="invalid-feedback">
                        <div *ngIf="f.originAddress.errors.required">{{ 'tabs.errors.pickup_address' | translate }}</div>
                    </div>
                </div>
                <div class="form-group col">
                    <label>{{ 'tabs.dropoff' | translate }}</label>
                    <select name="p1" (change)="openDestinationAddress($event)" formControlName="destinationDropOff" 
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.destinationDropOff.errors }" >
                        <option value="">{{ 'tabs.options' | translate }}</option>
                        <option value="1">{{ 'tabs.airport' | translate }}</option>
                        <option value="2">{{ 'tabs.train_station' | translate }}</option>
                        <option value="3">{{ 'tabs.others' | translate }}</option>
                    </select>
                    <div *ngIf="submitted && f.destinationDropOff.errors" class="invalid-feedback">
                        <div *ngIf="f.destinationDropOff.errors.required">{{ 'tabs.errors.dropoff' | translate }}</div>
                    </div>
                </div>
                <div class="form-group col" [ngClass]="{ 'inactive': !showDestinationAddress, 'active': showDestinationAddress }">
                    <label>{{ 'tabs.dropoff_address' | translate }}</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        formControlName="destinationAddress" 
                        class="destinationAddress" 
                        [ngClass]="{ 'is-invalid': submitted && f.destinationAddress.errors }">
                    <div *ngIf="submitted && f.destinationAddress.errors" class="invalid-feedback">
                        <div *ngIf="f.destinationAddress.errors.required">{{ 'tabs.errors.dropoff_address' | translate }}</div>
                    </div>
                </div>
                <div class="form-group col">
                    <label>Email</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        formControlName="email" 
                        class="email" 
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">{{ 'tabs.errors.email_compulsory' | translate }}</div>
                        <div *ngIf="f.email.errors.email">{{ 'tabs.errors.email_bad' | translate }}</div>
                    </div>
                </div>
                <div class="form-group col">
                    <label>{{ 'tabs.notes' | translate }}</label>
                    <textarea 
                        type="text" 
                        class="form-control" 
                        formControlName="notes" 
                        class="notes"
                        placeholder="{{ 'tabs.notes_placeholder' | translate }}"></textarea>
                </div>
            </div>
            <div class="text-center">
                <button class="btn btn-primary mr-1" (click)="onSubmit()">{{ 'tabs.ok' | translate }}</button>
                <!--
                <button class="btn btn-secondary" type="reset" (click)="onReset()">Borrar</button>
                -->
            </div>
        </form>

        <div class="containerConfirmation" *ngIf="bookingConfirmation">
            <h1 class="mb-2 text-center text-white">Booking details</h1>
        
            <div class="dataConfirmation">
                <div class="dataBooking">
                    <br><br>
                    <span class="title">Email:</span> {{data.email}}
                    <br>
                    <span class="title">Date and time:</span> {{data.date_time}}
                    <br>
                    <span class="title">Pick up: </span>
                        <span *ngIf="data.address_origin === '3'">{{data.point_origin}}</span>
                        <span *ngIf="data.address_origin === '2'">Maria Zambrano Train Station</span>
                        <span *ngIf="data.address_origin === '1'">Malaga Airport</span>
                    <br>
                    <span *ngIf="data.address_origin === '1'" class="title">Flight Number: </span>
                    <span *ngIf="data.address_origin === '1'">{{data.flight_number}}</span>
                    <br>
                    <span class="title">Destination: </span>
                        <span *ngIf="data.address_destiny === '3'">{{data.point_destiny}}</span>
                        <span *ngIf="data.address_destiny === '2'">Maria Zambrano Train Station</span>
                        <span *ngIf="data.address_destiny === '1'">Malaga Airport</span>
                    <br>
                    <span class="title">Message:</span> {{data.notes}}
                    <br>
                    <br>
                    Thanks for the message! We will get back to you to confirm booking!
                    <br>
                    <br>
                    <p style="color:red;font-size:14px;font-style: italic;">Check spam in case you don't get any email from us</p>
                </div>
                <button class="btn btn-primary mr-1" (click)="newBooking()">New booking</button>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="{{ 'tabs.excursions' | translate }}"> 
         <div class="excursionBox">
            <div class="trip_information">
                <img src="/assets/images/ronda.jpeg"/>
                <div class="nameDescripcionBox">
                    <div class="title">
                        Ronda
                    </div>
                    <div class="text">
                        Déjate sorprender por el maravilloso pueblo de Ronda, el tercer destino turístico favorito en Andalucía que combina historia, paisajes y tradición.
                    </div>
                    <div class="price">
                        
                    </div>
                    <div class="book" (click)="openFormBookTrip()">
                        Resevar
                    </div>
                </div>
            </div>
            <div class="trip_booking">
                <app-templatetrip [tripBooking]="tripBooking"></app-templatetrip>
            </div>
         </div>
         <div class="excursionBox">
            <div class="trip_information">
                <img src="/assets/images/granada.png"/>
                <div class="nameDescripcionBox">
                    <div class="title">
                        La Alhambra
                    </div>
                    <div class="text">
                        La Alhambra, el monumento más visitado del país y una de las paradas imprescindibles en cualquier viaje por Andalucía.
                    </div>
                    <div class="price">
                        
                    </div>
                    <div class="book">
                    
                    </div>
                    <div class="link">
                        <a href="https://tickets.alhambra-patronato.es/entradas-alhambra-general-2/">Entradas</a>
                    </div>
                </div>
            </div>
            <div class="trip_booking">
                <app-templatetrip [tripBooking]="tripBooking"></app-templatetrip>
            </div>
         </div>
    </mat-tab>
    <!--
    <mat-tab label="POR HORAS"> Content 2 </mat-tab>
    <mat-tab label="BODAS"> Content 3 </mat-tab>
    <mat-tab label="CONGRESOS"> Content 3 </mat-tab>
    -->
</mat-tab-group>
<div class="loading" *ngIf="loading">
    <img src="/assets/images/loading.gif">
</div>
