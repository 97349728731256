import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  public laravelApi = environment.apiURL + 'api/';

  private dataSaved: any;

  constructor(private http: HttpClient) { }

  public sendForm(form: any): Promise<any> {

    let data = new Object();

    _.forEach(form, (item, key) => {
      data[key] = item.value;
    });

    const json = JSON.stringify(data);
    const params = 'data=' + json;

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return new Promise<any>((resolve, reject) => {
        this.http.post(this.laravelApi + 'sendForm', params, { headers })
            // .pipe(map((res: Response) => res.json()))
            .subscribe(
                (res) => {
                    resolve(res);
                },
                (error) => {
                    reject(error);
                }
            );
    });
  }

  getData() {
    return this.dataSaved;
  }

  updateData(data: any) {
    this.dataSaved = data;
  }

}