    <div class="all_day">
        <span class="material-icons">
            calendar_today
        </span>
        <div class="text">
            {{ "footer.calendar_today" | translate }}
        </div>
    </div>

    <div class="online_booking">
        <span class="material-icons">
            laptop_chromebook
        </span>
        <div class="text">
            {{ "footer.laptop_chromebook" | translate }}
        </div>
    </div>

    <div class="customer_service">
        <span class="material-icons">
            account_circle
        </span>
        <div class="text">
            {{ "footer.account_circle" | translate }}
        </div>
    </div>

    <div class="price_quality">
        <span class="material-icons">
            star_rate
        </span>
        <div class="text">
            {{ "footer.star_rate" | translate }}
        </div>
    </div>

    <div class="prices">
        <span class="material-icons">
            euro
        </span>
        <div class="text">
            {{ "footer.euro" | translate }}
        </div>
    </div>

    <div class="avoid_wait">
        <span class="material-icons">
            schedule
        </span>
        <div class="text">
            {{ "footer.schedule" | translate }}
        </div>
    </div>
