import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContactComponent } from './static/contact/contact.component';
import { BenefitsComponent } from './static/benefits/benefits.component';
import { WeareComponent } from './static/weare/weare.component';
import { OfferedComponent } from './static/offered/offered.component';
import { AppComponent } from './app.component';

const routes: Routes = [
  { path: 'benefits', component: BenefitsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'offered', component: OfferedComponent },
  { path: 'weare', component: WeareComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
