import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from '../services/form.service';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit, AfterViewInit {

  tripBooking = false;

  registerServiceForm: FormGroup;
  submitted = false;
  showOriginAddress = false;
  showDestinationAddress = false;
  showFlightNumber = false;
  testDefaultErrorsRequired = false;
  loading = false;
  bookingConfirmation = false;
  public data: any;

  items = [1, 2, 3, 4, 5, 6, 7];

  constructor(
    private formBuilder: FormBuilder,
    private formService: FormService,
    private router: Router) { }

  ngOnInit(): void {
    this.registerServiceForm = this.formBuilder.group({
      test_default: [null],
      datepickergo: [null],
      originPickUp: [null, Validators.required],
      flightNumber: [null],
      originAddress: [null],
      destinationDropOff: [null, Validators.required],
      destinationAddress: [null],
      passengers: [1],
      email: [null, [Validators.required, Validators.email]],
      notes: [null]
    });

    $(document).ready( () => {
      $( () => {
        const opt = {
          date: {preset : 'date'},
          datetime: { preset : 'datetime', minDate: new Date(2018, 3, 10, 9, 22), maxDate: new Date(2023, 7, 30, 15, 44), stepMinute: 1  },
          time: {preset : 'time'},
          tree_list: {preset : 'list', labels: ['Region', 'Country', 'City']},
          image_text: {preset : 'list', labels: ['Cars']},
          select: {preset : 'select'},
        };

        $('select.changes').bind('change', () => {
            let demo = $('#demo').val();
            $('.demos').hide();
            if (!($('#demo_' + demo).length)) {
              demo = 'default';
            }

            $('#demo_' + demo).show();
            $('#test_default').val('').scroller('destroy').scroller($.extend(opt[$('#demo').val()], { theme: $('#theme').val(),
              mode: $('#mode').val(), display: $('#display').val(), lang: $('#language').val() }));
        });
        $('#demo').trigger('change');
      });
    });
  }

  get f() { return this.registerServiceForm.controls; }

  removeErrors() {
    if (document.forms['formBooking']['test_default'].value === '') {
      this.testDefaultErrorsRequired = true;
    } else {
      this.testDefaultErrorsRequired = false;
    }
  }

  onSubmit() {
    this.removeErrors();

    this.submitted = true;

    // stop here if form is invalid
    if (this.registerServiceForm.invalid || this.testDefaultErrorsRequired) {
        return;
    }

    this.loading = true;
    this.registerServiceForm.patchValue({datepickergo: document.forms['formBooking']['test_default'].value});
    this.formService.sendForm(this.f).then((data) => {
      this.data = data;
      this.bookingConfirmation = true;
      this.loading = false;
    }).catch((error) => {
      console.log(error);
      console.log('ERROR COMPONENT');
    });
  }

  onReset() {
    this.submitted = false;
    this.registerServiceForm.reset();
  }

  newBooking() {
    this.onReset();
    this.bookingConfirmation = false;
  }

  ngAfterViewInit() {
    $( () => {
      $( '.datepickergo' ).datetimepicker(
        {
          timeText: 'Hora',
          hourText: 'Horas',
          minuteText: 'Minutos',
          closeText: 'Cerrar',
          prevText: '&#x3C;Ant',
          nextText: 'Sig&#x3E;',
          currentText: 'Hoy',
          monthNames: ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre'],
          monthNamesShort: ['ene','feb','mar','abr','may','jun','jul','ago','sep','oct','nov','dic'],
          dayNames: ['domingo','lunes','martes','miércoles','jueves','viernes','sábado'],
          dayNamesShort: ['dom','lun','mar','mié','jue','vie','sáb'],
          dayNamesMin: ['D','L','M','X','J','V','S'],
          weekHeader: 'Sm',
          dateFormat: 'dd/mm/yy',
          firstDay: 1,
          showMonthAfterYear: false,
          yearSuffix: '',
          maxDate: new Date(2022, 12, 31, 23, 59),
          addSliderAccess: true,
          sliderAccessArgs: {
            touchonly: false
          },
        }
      );
    } );
  }

  openOriginAddress(event) {
    this.showOriginAddress = event.target.selectedIndex === 3 ? true : false;
    this.showFlightNumber = event.target.selectedIndex === 1 ? true : false;
  }

  openDestinationAddress(event) {
    this.showDestinationAddress = event.target.selectedIndex === 3 ? true : false;
  }

  hideElements(): void {
    this.testDefaultErrorsRequired = false;
    $( '.ui-datepicker-current' ).hide();
    $( '.ui_tpicker_time' ).hide();
  }

  openFormBookTrip(){
    this.tripBooking = true;
  }

}
