<app-header></app-header>
<!--
<app-sidenav></app-sidenav>
-->


<div class="titleHome">
    
</div>

<app-tabs></app-tabs>


<video [muted]="true" autoplay loop="true">
    <source src="/assets/videos/malaga.mp4" type="video/mp4" />
</video>

<app-footer></app-footer>

<router-outlet></router-outlet>