<header>
  <h1>{{ "contact.header" | translate }}</h1>
</header>

<div class="content">
  <form>
    <mat-form-field>
      <input matInput placeholder="{{ 'contact.name' | translate }}" required>
      <mat-icon matSuffix>person</mat-icon>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{ 'contact.email' | translate }}" required>
      <mat-icon matSuffix>drafts</mat-icon>
    </mat-form-field>
    <mat-form-field>
      <input type="tel" matInput placeholder="{{ 'contact.phone' | translate }}" required>
      <mat-icon matSuffix>call</mat-icon>
    </mat-form-field>
    <mat-form-field>
      <textarea matInput placeholder="{{ 'contact.message' | translate }}" required></textarea>
      <mat-icon matSuffix>create</mat-icon>
    </mat-form-field>
    <button mat-raised-button class="btn-custom">{{ 'contact.sendnow' | translate }}<i class="material-icons">
        send
      </i></button>
  </form>
</div>