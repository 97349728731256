import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SidenavService } from '../services/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor(private sidenav: SidenavService) { }

  toggleRightSidenav() {
      this.sidenav.toggle();
  }

}
