import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ReactiveFormsModule } from '@angular/forms';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidenavComponent } from './sidenav/sidenav.component';
import { HeaderComponent } from './header/header.component';
import { SliderComponent } from './slider/slider.component';
import { FooterComponent } from './footer/footer.component';
import { TranslationComponent } from './translation/translation.component';
import { BenefitsComponent } from './static/benefits/benefits.component';
import { ContactComponent } from './static/contact/contact.component';
import { OfferedComponent } from './static/offered/offered.component';
import { WeareComponent } from './static/weare/weare.component';

import { TemplatetripComponent } from './tabs/templatetrip/templatetrip.component';

import { SidenavService } from './services/sidenav.service';
import { FormService } from './services/form.service';

import { MaterialModule } from './material.module';
import { TabsComponent } from './tabs/tabs.component';

import { FormsModule } from '@angular/forms';

@NgModule({
  exports: [
    MaterialModule
  ],
  declarations: [
    AppComponent,
    SidenavComponent,
    HeaderComponent,
    ContactComponent,
    SliderComponent,
    FooterComponent,
    TranslationComponent,
    OfferedComponent,
    WeareComponent,
    BenefitsComponent,
    TabsComponent,
    TemplatetripComponent
  ],
  imports: [
    MaterialModule,
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    }),
  ],
  providers: [
    SidenavService,
    FormService
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
