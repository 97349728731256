<form class="registerTripForm" id="formBooking" [formGroup]="registerServiceForm" *ngIf="tripBooking">
    <div class="form-row">
        <div class="form-group col">
            <div class="dataCustomer">
                <div class="d-none">
                    <label for="theme">Theme</label>
                    <select name="theme" id="theme" class="changes">
                        <option value="default" selected>Default</option>
                        <option value="android">Android</option>
                        <option value="android-ics light">Android ICS Light</option>
                        <option value="android-ics">Android ICS</option>
                        <option value="ios">iOS</option>
                        <option value="jqm">Jquery Mobile</option>
                        <option value="sense-ui">Sense UI</option>
                        <option value="wp light">Windows Phone Light</option>
                        <option value="wp">Windows Phone</option>
                    </select>
                </div>
                <div class="d-none">
                    <label for="mode">Mode</label>
                    <select name="mode" id="mode" class="changes">
                        <option value="scroller" selected>Scroller</option>
                        <option value="clickpick">Clickpick</option>
                        <option value="mixed">Mixed</option>
                    </select>
                </div>
                <div class="d-none">
                    <label for="display">Display</label>
                    <select name="display" id="display" class="changes">
                        <option value="modal" selected>Modal</option>
                        <option value="inline">Inline</option>
                        <option value="bubble">Bubble</option>
                        <option value="top">Top</option>
                        <option value="bottom">Bottom</option>
                    </select>
                </div>
                <div class="d-none">
                    <label for="language">Language</label>
                    <select name="language" id="language" class="changes">
                        <option value="">English</option>
                        <option value="hu">Magyar</option>
                        <option value="de">Deutsch</option>
                        <option value="es"selected>Espa�ol</option>
                        <option value="fr">Fran�ais</option>
                        <option value="it">Italiano</option>
                        <option value="no">Norsk</option>
                        <option value="pt-BR">Pr Brasileiro</option>
                        <option value="zh">Chinese</option>
                        <option value="nl">Nederlands</option>
                        <option value="tr">T�rk�e</option>
                        <option value="ja">Japanese</option>
                        <!--Lang-->
                    </select>
                </div>
                <div class="d-none">
                    <label for="demo">Demo</label>
                    <select name="demo" id="demo" class="changes">
                        <option value="date" selected>Date</option>
                        <option value="datetime" selected>Datetime</option>
                        <option value="time" >Time</option>
                        <option value="tree_list" >Tree List</option>
                        <option value="image_text" >Image & Text</option>
                        <option value="select" >Select</option>
                        <!--Demos-->
                    </select>
                </div>
                <div class="form-group demos" id="demo_default">
                  <label for="Name">{{ 'tabs.pickup_time' | translate }} </label>
                  <input type="text" data-field="datetime" name="test_default" formControlName="test_default" id="test_default" class="date form-control" readonly>
                  <div id="dtBox"></div>
                  <div *ngIf="submitted && testDefaultErrorsRequired" style="width: 100%; margin-top:0.25rem;font-size:80%;color: #dc3545;">
                    <div *ngIf="testDefaultErrorsRequired">{{ 'tabs.errors.pickup_time' | translate }}</div>
                  </div>
                </div>
              </div>
        </div>
        <div class="form-group col">
            <label for="passengers" class="passengers">{{ 'tabs.passengers' | translate }} </label>
            <select name="passengers" formControlName="passengers" id="passengers" class="form-control">
              <option *ngFor="let item of items" value="{{item}}">{{item}}</option>
            </select>
        </div>
        <div class="form-group col" [ngClass]="{ 'inactive': !showOriginAddress, 'active': showOriginAddress }">
            <label>{{ 'tabs.pickup_address' | translate }}</label>
            <input 
                type="text" 
                class="form-control" 
                formControlName="originAddress" 
                class="originAddress" 
                [ngClass]="{ 'is-invalid': submitted && f.originAddress.errors }">
            <div *ngIf="submitted && f.originAddress.errors" class="invalid-feedback">
                <div *ngIf="f.originAddress.errors.required">{{ 'tabs.errors.pickup_address' | translate }}</div>
            </div>
        </div>
        <div class="form-group col">
            <label>Email</label>
            <input 
                type="text" 
                class="form-control" 
                formControlName="email" 
                class="email" 
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">{{ 'tabs.errors.email_compulsory' | translate }}</div>
                <div *ngIf="f.email.errors.email">{{ 'tabs.errors.email_bad' | translate }}</div>
            </div>
        </div>
        <div class="form-group col">
            <label>{{ 'tabs.notes' | translate }}</label>
            <textarea 
                type="text" 
                class="form-control" 
                formControlName="notes" 
                class="notes"
                placeholder="{{ 'tabs.notes_placeholder' | translate }}"></textarea>
        </div>
    </div>
    <div class="text-center">
        <button class="btn btn-primary mr-1" (click)="onSubmit()">{{ 'tabs.ok' | translate }}</button>
    </div>
</form>

<div class="containerConfirmation" *ngIf="bookingConfirmation">
    <h1 class="mb-2 text-center text-white">Booking details</h1>

    <div class="dataConfirmation">
        <div class="dataBooking">
            <br><br>
            <span class="title">Email:</span> {{data.email}}
            <br>
            <span class="title">Date and time:</span> {{data.date_time}}
            <br>
            <span class="title">Pick up: </span>
                <span *ngIf="data.address_origin === '3'">{{data.point_origin}}</span>
                <span *ngIf="data.address_origin === '2'">Maria Zambrano Train Station</span>
                <span *ngIf="data.address_origin === '1'">Malaga Airport</span>
            <br>
            <span *ngIf="data.address_origin === '1'" class="title">Flight Number: </span>
            <span *ngIf="data.address_origin === '1'">{{data.flight_number}}</span>
            <br>
            <span class="title">Destination: </span>
                <span *ngIf="data.address_destiny === '3'">{{data.point_destiny}}</span>
                <span *ngIf="data.address_destiny === '2'">Maria Zambrano Train Station</span>
                <span *ngIf="data.address_destiny === '1'">Malaga Airport</span>
            <br>
            <span class="title">Message:</span> {{data.notes}}
            <br>
            <br>
            Thanks for the message! We will get back to you to confirm booking!
            <br>
            <br>
            <p style="color:red;font-size:14px;font-style: italic;">Check spam in case you don't get any email from us</p>
        </div>
        <button class="btn btn-primary mr-1" (click)="newBooking()">New booking</button>
    </div>
</div>