<header>
    <h1>{{ "about.header" | translate }}</h1>
</header>

<div class="content">
    <p>{{ "about.content.line1" | translate }}</p> 
    <p>{{ "about.content.line2" | translate }}</p> 
    <p>{{ "about.content.line3" | translate }}</p> 
    <p>{{ "about.content.line4" | translate }}</p> 
    <div>
        {{ "about.ul.header" | translate }} 
        <ul>
            <li>{{ "about.ul.li1" | translate }} </li>
            <li>{{ "about.ul.li2" | translate }} </li>
            <li>{{ "about.ul.li3" | translate }} </li>
            <li>{{ "about.ul.li4" | translate }} </li>
            <li>{{ "about.ul.li5" | translate }} </li>
            <li>{{ "about.ul.li6" | translate }} </li>
            <li>{{ "about.ul.li7" | translate }} </li>
            <li>{{ "about.ul.li8" | translate }} </li>
            <li>{{ "about.ul.li9" | translate }} </li>
        </ul>
    </div>
</div>

